import React from 'react';

const Modal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
    <div className="modal-content ">
      <span
        className="close -mt-5"
        onClick={onClose}
      >
        &times;
      </span>
      <div className="section-title ">
        <h4 className="text-1xl font-semibold uppercase mt-3 mb-4">
          {title}
        </h4>
        <hr/>
      <div className="flex flex-1 overfl">
        {children}
      </div>
      </div>
    </div>
  </div>
  );
};

export default Modal;
