import React from 'react'
import { format } from 'date-fns';

const WhatsappChatUi = (props) => {
    const { chatMessageData } = props

    const formatTime = (timestamp) => {
        return format(new Date(timestamp), 'p'); // 'p' is the pattern for time in 12-hour format with AM/PM
    };


    return (
        <>
            <div className="chat-container chat-container-large overflow-hidden  ">
                <div className="chat">
                    <div className="chat-header">
                        <div className="profile">
                            <div className="left">
                                <img src="static/assets/img/arrow.png" alt="Arrow" className="arrow" />
                                {/* <img src="static/assets/img/pp.png" alt="Profile" className="pp" /> */}
                            </div>
                            <div className="right">
                                <img src="static/assets/img/video.png" alt="Video Call" className="icon" />
                                <img src="static/assets/img/phone.png" alt="Phone Call" className="phone" />
                                <img src="static/assets/img/more.png" alt="More Options" className="icon" />
                            </div>
                        </div>
                    </div>
                    <div className="chat-box " style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/bg.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
                        {chatMessageData && chatMessageData.map((chatMessage) => (chatMessage.message_type == "sent" ?
                            (
                                <div className="chat-r">
                                    <div className="sp"></div>
                                    <div className="mess mess-r">
                                        <div className="mess-content">
                                            <pre>
                                                {chatMessage.content.template && chatMessage.content.template.header_content && (
                                                    <pre className="font-bold">{chatMessage.content.template.header_content}</pre>
                                                )}

                                                {chatMessage.content.template && chatMessage.content.template.content && (
                                                    <pre>{chatMessage.content.template.content}</pre>
                                                )}
                                            </pre>
                                            <div className="check">
                                                <span>{formatTime(chatMessage.timestamp)}</span>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="chat-l" key={chatMessage.id}>
                                    <div className="mess">
                                        <div className="mess-content">
                                            {/* Check if the message has an image and display it */}
                                            {chatMessage.image_url && (
                                                <img
                                                    src={chatMessage.image_url}
                                                    alt="Chat Media"
                                                    className="chat-media"
                                                    style={{ maxWidth: "100px", borderRadius: "8px" }}
                                                />
                                            )}

                                            {/* Check if the message has content and display it */}
                                            {chatMessage.content && (
                                                <pre>{chatMessage.content}</pre>
                                            )}
                                            <div className="check">
                                                <span>{formatTime(chatMessage.timestamp)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sp"></div>
                                </div>

                            )))}
                    </div>

                    <div className="chat-footer">
                        <img src="static/assets/img/emo.png" alt="Emoji" className="emo" />
                        <textarea placeholder="Message" readOnly></textarea>
                        <div className="icons">
                            {/* <img src="static/assets/img/attach file.png" alt="Attach File" />
                            <img src="static/assets/img/camera.png" alt="Camera" /> */}
                        </div>
                        <img src="static/assets/img/mic.png" alt="Mic" className="mic" />
                    </div>
                </div>
            </div>
        </>
    )

}

export default WhatsappChatUi;