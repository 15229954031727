import React, { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Axios, ExternalAxios} from "./axiosInstances";
import Loader from "./Loader";
import axios  from "axios"
import "./templates.css";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'

function Templates() {

  const fileInputRef = useRef(null);

  const [loading,setLoading]=useState(false)
  const [templatesData, setTemplatesData] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [variablesHeader, setVariablesHeader] = useState({});
  // const [templateCategory,setTemplateCategory]=useState()
  // const [templateCategoryList,setTemplateCategoryList]=useState()
  const [addTemplateHeaderContent,setAddTemplateHeaderContent]=useState('')
  const [addTemplateBodyContent,setAddTemplateBodyContent]=useState('')
  const textareaRef = useRef(null);
  const textareaRefHeader = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [websiteName, setWebsiteName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  
  const [templateName,setTemplateName ] = useState('');
  const [templateId,setTemplateId ] = useState(null);
  const [headerMediaType,setHeaderMediaType]=useState("none");

  const [headerDocumentLink,setHeaderDocumentLink]=useState('');
  const [headerDocumentName,setHeaderDocumentName]=useState('');
  const [headerDocumentupload,setHeaderDocumentUpload]=useState('');
  const [headerDocumentId,setHeaderDocumentId]=useState('');

  const [headerImageLink,setHeaderImageLink]=useState('');
  const [headerImageupload,setHeaderImageUpload]=useState('');
  const [headerImageId,setHeaderImageId]=useState('');

  const [addTemplateHeaderMediaLink,setAddTemplateHeaderMediaLink]=useState('');
  const [addTemplateHeaderMediaupload,setAddTemplateHeaderMediaUpload]=useState('');
  const [addTemplateHeaderMediaId,setAddTemplateHeaderMediaId]=useState('');

  const [whatsappAccessToken,setWhatsappAccessToken]=useState('');
  const [whatsappPhoneNumberId,setWhatsappPhoneNumberId]=useState('');
  const [whatsappBusinessAccountId,setWhatsappBusinessAccountId]=useState('');

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const selectedRows = useSelector((state) => state.selectedCustomerList.customerList);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  
  const templatesDataFetch = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/templateviewset/list_templates/?company_id=${userInfo.data.iCompanyID}`,
        config
      );

      if (res.data.template_list) {
        setTemplatesData(res?.data.template_list);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const handleOpenModal = () => {
    setShowModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleCloseModal = () => {
    setHeaderDocumentId('')
    setHeaderDocumentLink('')
    setHeaderDocumentName('')
    setHeaderDocumentUpload(null)
    setHeaderImageId('')
    setHeaderImageLink('')
    setHeaderImageUpload(null)
    setSelectedTemplate('')
    setVariables('')
    setIsAdding(false)
    setShowModal(false);
    document.body.classList.remove("overflow");
  };

  const handleAddTemplateOpenModal = () => {
    setShowAddTemplateModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleAddTemplateCloseModal = () => {
    setVariables({});
    setVariablesHeader({});
    setAddTemplateHeaderContent('');
    setHeaderMediaType('');
    setTemplateName('');
    setTemplateId('')
    setAddTemplateBodyContent('');
    setWebsiteName('');
    setWebsiteURL('');
    setAddTemplateHeaderMediaId('');
    setAddTemplateHeaderMediaLink('');
    setAddTemplateHeaderMediaUpload(null);
    setIsAdding(false);
    setShowAddTemplateModal(false);
    document.body.classList.remove("overflow");
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
  };

  const adjustTextareaHeightHeader = () => {
    if (textareaRefHeader.current) {
    const textarea = textareaRefHeader.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleAddTemplateSelection = () =>{
    handleAddTemplateOpenModal();
  }

  const handleTemplateHeaderChange = (template) => {
    adjustTextareaHeightHeader();
    const header_content=template.header_content
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...header_content.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();

      if (variable == "company_name") {
        vars[variable] = userInfo&&userInfo.data&&userInfo.data.vCompanyName;
      }
      else if (variable !== "customer_name") {
        vars[variable] = variables[variable] || variable;
      }
    });
    setVariablesHeader(vars);
  };

  const handleTemplateChange = (template) => {
    adjustTextareaHeight();
    const body_content=template.content
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...body_content.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();

      if (variable == "company_name") {
        vars[variable] = userInfo&&userInfo.data&&userInfo.data.vCompanyName;
      }
      else if (variable !== "customer_name") {
        vars[variable] = variables[variable] || variable;
      }
    });
    setVariables(vars);
  };

  const handleAddTemplateHeaderChange = (textContent) => {
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...textContent.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();
      vars[variable] = variablesHeader[variable] || "";
    });
    setVariablesHeader(vars);
  };

  const handleAddTemplateChange = (textContent) => {
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...textContent.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();
      vars[variable] = variables[variable] || "";
    });
    setVariables(vars);
  };

  const handleInputHeaderChange = (e) => {
    const { name, value } = e.target;
    setVariablesHeader((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariables((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const renderAddTemplateHeader = () => {
    let renderedTemplate = addTemplateHeaderContent;
    for (const [key, value] of Object.entries(variablesHeader)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue = value
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };

  const renderAddTemplate = () => {
    let renderedTemplate = addTemplateBodyContent;
    for (const [key, value] of Object.entries(variables)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue = value
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };

  const AddTemplateSubmitHandler = (e) => {
    e.preventDefault();
    const sampleHeaderVariables = Object.values(variablesHeader);
    const sampleVariables = Object.values(variables);
    try{
    addTemplateData({
      company_id:userInfo.data.iCompanyID,
      template_name:templateName,
      whatsapp_template_id:templateId,
      slug:templateName,
      header_type:headerMediaType,
      header_content:addTemplateHeaderContent,
      media_link: addTemplateHeaderMediaLink,
      media_id: addTemplateHeaderMediaId,
      content:addTemplateBodyContent,
      sample_header_dict:variablesHeader,
      sample_content_dict:variables,
      website_name:websiteName,
      website_url:websiteURL,
    });

  } catch (error) {
    console.error("Error:", error);
    toast.error('Failed to submit the form. Please try again.');
  }
  };

  const addTemplateData= async (combinedData) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      console.log(combinedData.whatsapp_template_id)

      const apiEndpoint = combinedData.whatsapp_template_id
      ? `/templateviewset/manage_template/${combinedData.whatsapp_template_id}/` // Endpoint for editing
      : `/templateviewset/manage_template/`; // Endpoint for adding

    const httpMethod = combinedData.whatsapp_template_id ? "put" : "post";
    const res = await Axios[httpMethod](apiEndpoint, combinedData, config);

      if(res.status&&res.data.success){
        toast.success(res.data.message)
        await templatesDataFetch()

      } else{
        toast.error(res.data.error)
        setLoading(false);
      }
      setTemplateName("");
      setHeaderMediaType("");
      setAddTemplateHeaderContent("");
      setAddTemplateBodyContent("");
      setTemplateName("");
      setLoading(false);
      handleAddTemplateCloseModal()
    } catch (error) {
      setLoading(false);
      if (error.response) {
        Swal.fire({
          title: 'Error!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4F46E5',
        })
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
      
    }
  };

  const handleTemplateDelete= async (template) => {
    Swal.fire({
      title: template.template_name,
      text: "Do you want to delete the template? This action cannot be undone.",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ed143d",
      cancelButtonColor: "#6b7280",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        DeleteTemplateApiCall(template)
      }
    });
  };
  
  const DeleteTemplateApiCall= async (template) =>{
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const delete_template_data = {
        template_id: template.id
      }

      const res = await Axios.post(
        `/templateviewset/delete_template/`,
        delete_template_data,
        config
      );

      if(res.status&&res.data.success){
        toast.success(res.data.message)
        await templatesDataFetch()
      } else{
        toast.error(res.data.error)
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  }

  const handleTemplateEdit= async (template) => {
    setTemplateName(template.template_name)
    setHeaderMediaType(template.header_type)
    setAddTemplateHeaderContent(template.header_content)
    setAddTemplateBodyContent(template.content)
    setTemplateId(template.whatsapp_template_id)
    if(template.sample_values_json.header_example) {
      setVariablesHeader(template.sample_values_json.header_example);
    } else {
      handleAddTemplateHeaderChange(template.header_content)
    }
    if(template.sample_values_json.content_example) {
      setVariables(template.sample_values_json.content_example);
    } else {
      handleAddTemplateChange(template.content)
    }
    if(template.button_components_json.buttons) {
      setIsAdding(true);
      setWebsiteName(template.button_components_json.buttons[0].text);
      setWebsiteURL(template.button_components_json.buttons[0].url);
    }
    handleAddTemplateOpenModal()  
  };

  
  const handleAddTemplateHeaderContentChange = (e) => {
    setAddTemplateHeaderContent(e.target.value);
    handleAddTemplateHeaderChange(e.target.value);
  };
  const handleAddTemplateBodyContentChange = (e) => {
    setAddTemplateBodyContent(e.target.value);
    handleAddTemplateChange(e.target.value);
  };


  const handleAddTemplateMediaFileChange = async (e) => {
    try {

      const file = e.target.files[0];
      setAddTemplateHeaderMediaUpload(e.target.files[0]);
      setAddTemplateHeaderMediaLink('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp'); 

      const config = {
        headers: {
          Authorization: `Bearer ${whatsappAccessToken}`,
          // 'Content-Type': 'multipart/form-data',
        },
      };
      const whatsappUploadUrl = "https://graph.facebook.com/v20.0/" + whatsappPhoneNumberId + "/media";
      const res = await axios.post(
        whatsappUploadUrl,
        formData,
        config
      );

      if(res.data&&res.data.id){
        setAddTemplateHeaderMediaId(res.data.id);
        toast.success("Media uploaded successfully!")
      } else{
        toast.error(res.data.error)
      }
      
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Handle link input
  const handleAddTemplateMediaLinkChange = (e) => {
    setAddTemplateHeaderMediaLink(e.target.value);
    setAddTemplateHeaderMediaUpload(null); 
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    } 
  };

  const handleHeaderMediaTypeChange = (e) => {
    const value = e.target.value;
    setHeaderMediaType(value);
  
    // Reset the corresponding fields based on selected value
    if (value === "none") {
      setAddTemplateHeaderContent("");
      setAddTemplateHeaderMediaLink("");
      setAddTemplateHeaderMediaUpload(null); 
    }
    else if (value === "text") {
      setAddTemplateHeaderMediaLink("");
      setAddTemplateHeaderMediaUpload(null);
    }
    else if (value == "document" || "image") {
      setAddTemplateHeaderMediaLink(""); 
      setAddTemplateHeaderMediaUpload(null);
    }
  };

  const ConnectToWhatsappDataGet = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await Axios.get(
        `/whatsappviewset/get_whatsapp_token/${userInfo.data.iCompanyID}`,
        config
      );
      if(data){
        setWhatsappAccessToken(data.access_token);
        setWhatsappPhoneNumberId(data.phone_number_id);
        setWhatsappBusinessAccountId(data.business_account_id);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleDeleteField = () => {
    setIsAdding(false)
  };


  useEffect(() => {
    if(!token){
      navigate('/error')
      return;
    }
    
    if(!templatesData){
      templatesDataFetch()
      // templateCategoryData()
    }

    if(!whatsappAccessToken){
      ConnectToWhatsappDataGet()
    }
    if (textareaRef.current) {
      adjustTextareaHeight();
      adjustTextareaHeightHeader();
    }
    if (showModal) {
      handleTemplateChange(selectedTemplate);
      handleTemplateHeaderChange(selectedTemplate);
    }

    const handleClickOutsideModal = (event) => {
      if (event.target.className === "modal") {
        handleCloseModal();
        handleAddTemplateCloseModal();
        document.body.classList.remove("overflow");
      }
    };

    window.addEventListener("click", handleClickOutsideModal);

    return () => {
      window.removeEventListener("click", handleClickOutsideModal);
    };
  }, [selectedTemplate,selectedTemplate.content,showModal,showAddTemplateModal,selectedRows,dispatch,navigate,token]);

  

  return (
    <>
      {loading?
      (<Loader/>):
      (
        <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
          <SideBar />

          <main className="page-content bg-gray-50 dark:bg-slate-800">
            <Header toggleSidebar={handleToggleSidebar}/>
            <div className="container-fluid relative px-3">
              <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                  <div>
                    <h5 className="text-lg font-semibold">Templates</h5>
                  </div>
                  <div className="flex justify-end">
                  <button
                    onClick={() => {
                    handleAddTemplateSelection();
                    }}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md 
                      bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                  >
                    Add New Template
                  </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
                  {templatesData?templatesData.map((template) => (
                    <div
                      className={`relative flex flex-col justify-between rounded-md shadow dark:shadow-gray-700 overflow-hidden min-h-[300px]  ${
                        selectedTemplate === template.id
                          ? " border-4  !important"
                          : "bg-white dark:bg-slate-900"
                      }`}
                    >
                      <div className="content p-6 flex-grow">
                        <div className="flex align-center justify-between mb-4 ">
                          <h6 className="text-15 font-bold">
                            {template.template_name}
                          </h6>
                          {template.status === "APPROVED" && (
                            <div className="flex items-center bg-green-600 text-white rounded-full px-3 py-1 text-xs font-medium shadow">
                              <span className="h-2 w-2 rounded-full bg-green-500 mr-2"></span>
                              Approved
                            </div>
                          )}
                          {template.status === "PENDING" && (
                            <div className="flex items-center bg-yellow-500 text-white rounded-full px-3 py-1 text-xs font-medium shadow">
                              <span className="h-2 w-2 rounded-full bg-yellow-500 mr-2"></span>
                              Pending
                            </div>
                          )}
                          {template.status === "REJECTED" && (
                            <div className="flex items-center bg-red-600 text-white rounded-full px-3 py-1 text-xs font-medium shadow">
                              <span className="h-2 w-2 rounded-full bg-red-500 mr-2"></span>
                              Rejected
                            </div>
                          )}
                        </div>
                        
                    <SimpleBar style={{ maxHeight: "200px"}} autoHide={false}>

                        <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.header_content}</pre>
                        <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.content}</pre>
                      
                        <div className="variables mb-4">
                        </div>
                      </SimpleBar>  
                      </div>
                      <div className="px-6 pb-6 relative bottom-0 left-0">
                          <button
                            onClick={() => {
                              handleTemplateEdit(template);
                            }}
                            className={"py-2 px-2 mx-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-gray-700 rounded-md bg-gray-100 dark:bg-slate-800  border-gray-300 dark:border-gray-700 "
                            }
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/><path d="m15 5 4 4"/></svg>
                          </button>
                          <button
                            onClick={() => {
                              handleTemplateDelete(template);
                            }}
                            className="py-2 px-2 mx-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-gray-700 rounded-md bg-gray-100 dark:bg-slate-800  border-gray-300 dark:border-gray-700"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                          </button>
                      </div>
                    </div>
                  )):null}
                  {showAddTemplateModal && (
                      <div className="modal">
                        <div className="modal-content modal-content-wide">
                          <span
                            className="close -mt-5"
                            onClick={handleAddTemplateCloseModal}
                          >
                            &times;
                          </span>
                          <div className="section-title ">
                            <h4 className="text-1xl font-semibold uppercase mt-3 mb-4">
                              Add New Template
                            </h4>
                            <hr/>
                          <div className="flex flex-1 overflow">
                            <div className="w-full p-2">
                              <div className="pt-4">
                                <div className="simplebar-container overflow-auto" >
                                    <form onSubmit={AddTemplateSubmitHandler}>
                                      <div className="grid  gap-2 grid-col-1  md:grid-cols-12">
                                        <div className="xl:col-span-6 md:col-span-6">
                                          <label htmlFor="template_name" className="form-label font-semibold">
                                            Template Name
                                          </label>
                                          <div className="">
                                            <input
                                              type="text"
                                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                              name="template_name"
                                              id="template_name"
                                              onChange={(e) => setTemplateName(e.target.value)}
                                              value={templateName}
                                              placeholder="Template Name"
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="w-full col-span-12 mt-3 mb-3">
                                          <hr className="border-t border-gray-300 dark:border-gray-700" />
                                        </div>
                                        </div>
                                        <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                          <div className="xl:col-span-6 md:col-span-6">
                                            <label htmlFor="media" className="form-label font-medium">
                                              Header:
                                            </label>
                                            <select
                                              name="media"
                                              className="form-select form-input mb-3 w-full py-2 px-3 h-10 bg-transparent  dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                              onChange={handleHeaderMediaTypeChange}
                                              value={headerMediaType}
                                            >
                                              <option value="none" selected>
                                                None
                                              </option>
                                              <option value="text" >
                                                Text
                                              </option>
                                              {/* <option value="document" >
                                                Document
                                              </option>
                                              <option value="image" >
                                                Image
                                              </option> */}
                                              
                                            </select>
                                          </div>
                                        </div>
                                        {/* {(headerMediaType == "document" || headerMediaType == "image") &&
                                        <>
                                        <div className="w-full min-w-0">
                                          <div className="flex items-start  space-x-4 w-full">
                                            <div className="flex-1 min-w-0">
                                              <div className="w-full">
                                                <input
                                                  type="text"
                                                  className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                  name="document_link"
                                                  id="document_link"
                                                  onChange={handleAddTemplateMediaLinkChange}
                                                  value={addTemplateHeaderMediaLink}
                                                  placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                                />
                                              </div>
                                            </div>
                                            <div className="text-gray-500 font-medium px-2 whitespace-nowrap py-4">OR</div>

                                            <div className="flex-shrink-0 w-auto flex flex-col">
                                              <div className="flex flex-col">
                                              <label
                                                htmlFor="document_upload"
                                                className="w-full  mt-2 rounded-md   py-2 px-3 h-10 border border-indigo-600 text-indigo-600 hover:border-indigo-400 hover:bg-indigo-300 hover:text-indigo-400 cursor-pointer flex items-center justify-center  whitespace-nowrap"
                                              >
                                                Choose file
                                              </label>
                                              {addTemplateHeaderMediaupload &&
                                                <div className=" text-indigo-600  text-sm mt-1 text-center">
                                                  ({addTemplateHeaderMediaupload.name}) 
                                                </div>
                                              }
                                                <div className="">
                                                <input type="file" 
                                                onChange={handleAddTemplateMediaFileChange} 
                                                ref={fileInputRef}                                      
                                                name="document_upload"
                                                id="document_upload"
                                                accept={headerMediaType == "image"? "image/*" : headerMediaType === "document" ? ".pdf" : "" }
                                                className="hidden"/>
                                                </div>
                                              </div>
                                            </div>
                                          </div>  
                                        </div>
                                        </>
                                        } */}
                                        {headerMediaType=="text"&&
                                        <>
                                        <div className="xl:col-span-12 md:col-span-12">
                                          <textarea
                                            ref={textareaRefHeader}
                                            className="w-full p-4  mb-2 bg-gray-100 dark:bg-gray-800 outline-none resize-none  dark:text-white rounded-md  overflow-hidden border focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 "
                                            name="header_content"
                                            id="header_content"
                                            placeholder="Template Header"
                                            onChange={handleAddTemplateHeaderContentChange}
                                            onInput={adjustTextareaHeightHeader}
                                            value={addTemplateHeaderContent}
                                          />
                                        </div>
                                        <div>
                                          {Object.keys(variablesHeader).map((key) => (
                                            <div key={key} className="py-2">
                                              <label htmlFor={key}>
                                                {key.charAt(0).toUpperCase() +
                                                  key.slice(1)}
                                              </label>
                                              <div className="">
                                                <input
                                                  type="text"
                                                  className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                  name={key}
                                                  id={key}
                                                  value={variablesHeader[key]}
                                                  placeholder=""
                                                  onChange={handleInputHeaderChange}
                                                  required
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        </>}
                                        <div className="w-full col-span-12 mt-3 mb-3">
                                          <hr className="border-t border-gray-300 dark:border-gray-700" />
                                        </div>
                                        <div className="xl:col-span-12 md:col-span-12">
                                          <label className="font-semibold pb-3">Body:</label>
                                          <p dangerouslySetInnerHTML={{ __html: 'Use variables like {{customer_name}} to personalize your messages' }} className=" text-gray-500 font-medium"/>
                                          <textarea
                                            ref={textareaRef}
                                            className="w-full p-4 mt-2 mb-2 bg-gray-100 outline-none   dark:bg-gray-800 dark:text-white rounded-md border  focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 resize-none overflow-hidden min-h-[200px]"                                          
                                            name="body_content"
                                            id="body_content"
                                            placeholder="Template Body"
                                            required
                                            style={{ minHeight: '220px' }}
                                            onChange={handleAddTemplateBodyContentChange}
                                            onInput={adjustTextareaHeight}
                                            value={addTemplateBodyContent}
                                          />
                                          {Object.keys(variables).map((key) => (
                                            <div key={key} className="py-2">
                                              <label htmlFor={key}>
                                                {key.charAt(0).toUpperCase() +
                                                  key.slice(1)}
                                              </label>
                                              <div className="">
                                                <input
                                                  type="text"
                                                  className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                  name={key}
                                                  id={key}
                                                  value={variables[key]}
                                                  onChange={handleInputChange}
                                                  required
                                                />
                                              </div>

                                            </div>
                                          ))}
                                          </div>
                                        <div className="w-full col-span-12 mt-3 mb-3">
                                          <hr className="border-t border-gray-300 dark:border-gray-700" />
                                        </div>
                                      <div className="flex flex-col items-end space-y-4">
                                        <div className="flex justify-end">
                                          <button 
                                            onClick={handleAddButtonClick} 
                                            disabled={isAdding}
                                            className={`py-2 px-4 rounded-lg font-semibold  text-black border-2  border-black  ${
                                              isAdding ? 'bg-gray-200 cursor-not-allowed' : 'bg-gray-300 hover:bg-slate-500 hover:border-gray-700 '
                                            } transition-colors duration-300`}
                                    
                                          >
                                            Add Button
                                          </button>
                                        </div>
                                      
                                        {isAdding && (
                                        <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                          <div className="xl:col-span-5 md:col-span-5">
                                            <label htmlFor="website_name" className="">
                                              Website Name
                                            </label>
                                            <input
                                              type="text"
                                              id="website_name"
                                              name="website_name"
                                              value={websiteName}
                                              onChange={(e) => setWebsiteName(e.target.value)}
                                              className="form-input w-full py-2 px-3 h-10 mb-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                              placeholder="Enter website name"
                                            />
                                          </div>
                              
                                          <div className="xl:col-span-5 md:col-span-5">
                                            <label htmlFor="website_url" className="">
                                              Website URL
                                            </label>
                                            <input
                                              type="text"
                                              id="website_url"
                                              name="website_url"
                                              value={websiteURL}
                                              onChange={(e) => setWebsiteURL(e.target.value)}
                                              className="form-input w-full py-2 px-3 mb-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                              placeholder="Enter website URL"
                                            />
                                        </div>
                                        <div className="xl:col-span-2 md:col-span-2 flex flex-col justify-end mb-3">
                                          <div className="h-10 flex items-center">
                                          <span
                                            className="mdi mdi-trash-can cursor-pointer text-red-600 flex items-center justify-center h-10 bg-gray-100 dark:bg-slate-800 border border-gray-300 dark:border-gray-700 rounded p-2"
                                            style={{ fontSize: '24px' }}
                                            onClick={() => handleDeleteField()}
                                          ></span>
                                          </div>
                                        </div>
                                      </div>
                                        )}  
                                        <div className="flex justify-start">                              
                                      <button
                                        type="submit"
                                            className="py-2 px-5 font-semibold tracking-wide border border-indigo-600 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700 transition-colors duration-300"                                  >
                                            Save Template
                                      </button>
                                        </div>
                                      </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                            <div className="flex-none w-2/5 p-4 ">
                              <div className="mt-8">
                                <div className=" text-gray-800 text-xl flex items-center justify-center h-32 mt-4 mb-4">Preview</div>
                                <div className="">
                                  <div className="chat-container rounded-4xl overflow-hidden ">
                                    <div className="chat">
                                      <div className="chat-header">
                                        <div className="profile">
                                          <div className="left">
                                            {/* <img src="assets/img/arrow.png" alt="Arrow" className="arrow" /> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left"><path d="m12 19-7-7 7-7"/><path d="M19 12H5"/></svg>
                                            {/* <img src="assets/img/pp.png" alt="Profile" className="pp" /> */}
                                          </div>
                                          <div className="right">
                                            <div className=""><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 1 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-video"><path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"/><rect x="2" y="6" width="14" height="12" rx="2"/></svg></div>
                                            {/* <img src="assets/img/video.png" alt="Video Call" className="icon" /> */}
                                            <div className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg></div>
                                            {/* <img src="assets/img/phone.png" alt="Phone Call" className="phone" /> */}
                                            <div className=""><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="8 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ellipsis-vertical"><circle cx="12" cy="12" r="1"/><circle cx="12" cy="5" r="1"/><circle cx="12" cy="19" r="1"/></svg></div>
                                            {/* <img src="assets/img/more.png" alt="More Options" className="icon" /> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="chat-box " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/static/assets/img/bg.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                                        <div className="chat-l">
                                          <div className="mess">
                                            <div className="mess-content">
                                            {addTemplateHeaderContent && (
                                              <>
                                                <b><pre>{renderAddTemplateHeader()}</pre></b>
                                                {/* <br /> */}
                                              </>
                                            )}
                                            <pre>
                                            {renderAddTemplate()}
                                            </pre>
                                            <div className="check">
                                              <span>4:00 PM</span>
                                            </div>
                                            </div>
                                            <div className="p-2">
                                            {isAdding&&
                                            <div>
                                            <hr className="w-full border-0 border-t border-gray-200" style={{ margin: '4px 0 0 0' }} />
                                            <div className="flex justify-center items-center text-blue-600 p-2"> 
                                            <span class="mdi mdi-open-in-new"></span>
                                              <div className="truncate ms-1"> <a href={websiteURL || "!#"} target="_blank">{websiteName || "Visit website"}</a></div>
                                            </div>
                                            </div>}
                                            </div>
                                          </div>
                                          <div className="sp"></div>
                                        </div>
                                      </div>
                                      <div className="chat-footer">
                                        <img src="static/assets/img/emo.png" alt="Emoji" className="emo" />
                                        <textarea placeholder="Message" readOnly></textarea>
                                        <div className="icons">
                                          <div><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 -2 26 24" fill="none" stroke="#848c92" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-paperclip"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"/></svg></div>
                                          <div><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#848c92" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-camera"><path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"/><circle cx="12" cy="13" r="3"/></svg></div>
                                        </div>
                                        <img src="static/assets/img/mic.png" alt="Mic" className="mic" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      )}
                </div>
              </div>
            </div>
            <Footer />
          </main>
        </div>
      )
    } 
    </>
  );
}

export default Templates;
