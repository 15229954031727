import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import Home from "./componants/Home";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreateCustomer from "./componants/CreateCustomer";
import Templates from "./componants/Templates";
import CustomerListing from "./componants/CustomerListing";
import SelectTemplate from "./componants/SelectTemplate";
import CustomerType from "./componants/CustomerType";
import Error from "./componants/Error";
import MessageHistory from "./componants/MessageHistory";
import NewOrderTemplates from "./componants/NewOrderTemplates";
import GoldPriceTemplates from "./componants/GoldPriceTemplates";
import BatchHistory from "./componants/BatchHistory";
import ConnectToWhatsapp from "./componants/ConnectToWhatsapp";
import ChatWithUser from "./componants/ChatWithUser";
import AutoMessaging from "./componants/AutoMessaging"

function App() {
 
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/customer" element={<CreateCustomer />} />

            <Route path="/marketing-templates" element={<Templates />} />
            <Route path="/new-order-templates" element={<NewOrderTemplates />} />
            <Route path="/gold-price-templates" element={<GoldPriceTemplates />} />
            <Route path="/customer-listing/:category?" element={<CustomerListing />} />
            <Route path="/select-template" element={<SelectTemplate />} />
            <Route path="/customer-type" element={<CustomerType />} />
            <Route path="/batch-history" element={<BatchHistory />} />
            <Route path="/message-history" element={<MessageHistory />} />
            <Route path="/chat-list" element={<ChatWithUser />} />
            <Route path="/auto-messaging" element={<AutoMessaging />} />
            <Route path="/connect-to-whatsapp" element={<ConnectToWhatsapp />} />
            <Route path="/error" element={<Error />} />
            
          </Routes>
         
          <ToastContainer
          position="top-right"
          autoClose={3000}
          // hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ marginTop: '55px', marginRight: '20px' }}
        />
        
      </Router>
    </div>
  );
}

export default App;
