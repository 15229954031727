import React, { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Axios } from "./axiosInstances";
import Loader from "./Loader";
import axios  from "axios"
import "./templates.css";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { removeCustomerList,removeCheckedCustomerList } from "../features/selectedCustomerListSlice";

function SelectTemplate() {

  const fileInputRef = useRef(null);
  const [loading,setLoading]=useState(false)
  const [templatesData, setTemplatesData] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [variablesHeader, setVariablesHeader] = useState({});
  const textareaRef = useRef(null);
  const textareaRefHeader = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [websiteName, setWebsiteName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  
  const [headerMediaType,setHeaderMediaType]=useState("none");
  const [headerDocumentLink,setHeaderDocumentLink]=useState('');
  const [headerDocumentName,setHeaderDocumentName]=useState('');
  const [headerDocumentupload,setHeaderDocumentUpload]=useState('');
  const [headerDocumentId,setHeaderDocumentId]=useState('');

  const [headerImageLink,setHeaderImageLink]=useState('');
  const [headerImageupload,setHeaderImageUpload]=useState('');
  const [headerImageId,setHeaderImageId]=useState('');

  const [whatsappAccessToken,setWhatsappAccessToken]=useState('');
  const [whatsappPhoneNumberId,setWhatsappPhoneNumberId]=useState('');

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const selectedRows = useSelector((state) => state.selectedCustomerList.customerList);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  const templatesDataFetch = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/templateviewset/list_templates/?company_id=${userInfo.data.iCompanyID}`,
        config
      );

      if (res.data.template_list) {
        setTemplatesData(res?.data.template_list);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    document.body.classList.remove("overflow-hidden");
  };

  const handleCloseModal = () => {
    setHeaderDocumentId('')
    setHeaderDocumentLink('')
    setHeaderDocumentName('')
    setHeaderDocumentUpload(null)
    setHeaderImageId('')
    setHeaderImageLink('')
    setHeaderImageUpload(null)
    setSelectedTemplate('')
    setVariables({})
    setIsAdding(false)
    setShowModal(false);
    document.body.classList.remove("overflow");
  };

  const adjustTextareaHeightHeader = () => {
    if (textareaRefHeader.current) {
    const textarea = textareaRefHeader.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleTemplateSelection = (templateData) => {
    if (selectedRows == undefined || selectedRows == null) {
      toast.warning("Not Selected Any Customers.");
    }
    else{
      handleOpenModal();
      setSelectedTemplate(templateData);
      if(templateData.sample_values_json.header_example) {
        setVariablesHeader(templateData.sample_values_json.header_example);
      }
      if(templateData.sample_values_json.content_example) {
        setVariables(templateData.sample_values_json.content_example);
      }
      if(templateData.button_components_json.buttons) {
        setIsAdding(true);
        setWebsiteName(templateData.button_components_json.buttons[0].text);
        setWebsiteURL(templateData.button_components_json.buttons[0].url);
      }
      setHeaderMediaType(templateData.header_type)
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariables((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  
  const handleHeaderInputChange = (e) => {
    const { name, value } = e.target;
    setVariablesHeader((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const renderTemplateHeader = () => {
    adjustTextareaHeightHeader()
    let renderedTemplate = selectedTemplate.header_content;
    for (const [key, value] of Object.entries(variablesHeader)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue="{{"+value+"}}"
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };
  const renderTemplate = () => {
    adjustTextareaHeight()
    let renderedTemplate = selectedTemplate.content;
    for (const [key, value] of Object.entries(variables)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue="{{"+value+"}}"
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };

  const handleTemplateSave = (e) => {
    e.preventDefault();
    if (headerMediaType === 'document' && !headerDocumentLink && !headerDocumentupload) {
      toast.warning("Please provide a document link or upload a file.");
      return;
    }
    else if (headerMediaType === 'image' && !headerImageLink && !headerImageupload) {
      toast.warning("Please provide an image link or upload a file.");
      return;
    }
    const newContentHeader = renderTemplateHeader();
    const regexHeader = /{{\s*(.+?)\s*}}/g;
    const matchesHeader = [...newContentHeader.matchAll(regexHeader)];
    let varsHeader = new Set();
    matchesHeader.forEach((match) => {
      const variable = match[1].trim();
      varsHeader.add(variable);
    });

    const newContent = renderTemplate();
    const regex = /{{\s*(.+?)\s*}}/g;
    const matches = [...newContent.matchAll(regex)];
    let vars = new Set();
    matches.forEach((match) => {
      const variable = match[1].trim();
      vars.add(variable);
    });

    console.log([...vars], [...varsHeader])
    const buttons = [
      {
        type: 'url',
        url: websiteURL,
        text: websiteName
      }
    ]
    handleSendMessage([...vars], [...varsHeader], buttons)
    handleCloseModal()
  };

  const handleSendMessage = async (variableList,variableListHeader,buttons) => {    
      try {
        const combinedData = {
          users: selectedRows,
          template_slug:selectedTemplate.slug,
          template_name:selectedTemplate.template_name,
          company_id:userInfo.data.iCompanyID,
          body_vars:variableList,
          header_vars:variableListHeader,
          buttons:buttons,
          document_link: headerMediaType === 'document' ? headerDocumentLink : null,
          document_id: headerMediaType === 'document' ? headerDocumentId : null,
          document_name: headerMediaType === 'document' ? headerDocumentName : null,
          image_link: headerMediaType === 'image' ? headerImageLink : null,
          image_id: headerMediaType === 'image' ? headerImageId : null,
        };

        const config = {
          headers: {
            "Content-type": "application/json",
            "Accept":"application/json",
          },
        };

        const res = await Axios.post(
          "/whatsappviewset/send_whatsapp_message/",
          combinedData,
          config
        );
        
          Swal.fire({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
            })
            
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        console.log("Error:", error.message);
      }
      finally{
        dispatch(removeCustomerList())
        dispatch(removeCheckedCustomerList())
      }
    
  };

  const handleDocumentFileChange = async (e) => {
    try {

      const file = e.target.files[0];
      setHeaderDocumentUpload(e.target.files[0]);
      setHeaderDocumentLink('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp'); 

      const config = {
        headers: {
          Authorization: `Bearer ${whatsappAccessToken}`,
          // 'Content-Type': 'multipart/form-data',
        },
      };
      const whatsappUploadUrl = "https://graph.facebook.com/v20.0/" + whatsappPhoneNumberId + "/media";
      const res = await axios.post(
        whatsappUploadUrl,
        formData,
        config
      );

      if(res.data&&res.data.id){
        setHeaderDocumentId(res.data.id);
        toast.success("Document uploaded successfully!")
      } else{
        toast.error(res.data.error)
      }
      
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message)
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Handle link input
  const handleDocumentLinkChange = (e) => {
    setHeaderDocumentLink(e.target.value);
    setHeaderDocumentUpload(null); 
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    } 
  };

  const handleImageFileChange = async (e) => {
    try {

      const file = e.target.files[0];
      setHeaderImageUpload(e.target.files[0]);
      setHeaderImageLink('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp'); 

      const config = {
        headers: {
          Authorization: `Bearer ${whatsappAccessToken}`,
          // 'Content-Type': 'multipart/form-data',
        },
      };
      const whatsappUploadUrl = "https://graph.facebook.com/v20.0/" + whatsappPhoneNumberId + "/media";
      const res = await axios.post(
        whatsappUploadUrl,
        formData,
        config
      );

      if(res.data&&res.data.id){
        setHeaderImageId(res.data.id);
        toast.success("Image uploaded successfully!")
      } else{
        toast.error(res.data.error)
      }
      
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleTemplateChange = (template) => {
    adjustTextareaHeight();
    const body_content=template.content
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...body_content.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();

      if (variable == "company_name") {
        vars[variable] = userInfo&&userInfo.data&&userInfo.data.vCompanyName;
      }
      else if (variable !== "customer_name") {
        vars[variable] = variables[variable] || "";
      }
    });
    setVariables(vars);
  };

  const handleTemplateHeaderChange = (template) => {
    adjustTextareaHeightHeader();
    const header_content=template.header_content
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...header_content.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();

      if (variable == "company_name") {
        vars[variable] = userInfo&&userInfo.data&&userInfo.data.vCompanyName;
      }
      else if (variable !== "customer_name") {
        vars[variable] = variablesHeader[variable] || "";
      }
    });
    setVariablesHeader(vars);
  };

  // Handle link input
  const handleImageLinkChange = (e) => {
    setHeaderImageLink(e.target.value);
    setHeaderImageUpload(null); 
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    } 
  };

  const ConnectToWhatsappDataGet = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await Axios.get(
        `/whatsappviewset/get_whatsapp_token/${userInfo.data.iCompanyID}`,
        config
      );
      if(data){
        setWhatsappAccessToken(data.access_token);
        setWhatsappPhoneNumberId(data.phone_number_id);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    if(!token){
      navigate('/error')
      return;
    }
    
    if(!templatesData){
      templatesDataFetch()
      // templateCategoryData()
    }
    
    if(isEmptyObject(selectedRows)){
        navigate('/customer-listing')
    }

    if(!whatsappAccessToken){
      ConnectToWhatsappDataGet()
    }
    if (textareaRef.current) {
      adjustTextareaHeight();
      adjustTextareaHeightHeader();
    }
    if (showModal) {
      handleTemplateChange(selectedTemplate);
      handleTemplateHeaderChange(selectedTemplate);
    }

    const handleClickOutsideModal = (event) => {
      if (event.target.className === "modal") {
        handleCloseModal();
        document.body.classList.remove("overflow");
      }
    };

    window.addEventListener("click", handleClickOutsideModal);

    return () => {
      window.removeEventListener("click", handleClickOutsideModal);
    };
  }, [selectedTemplate,selectedTemplate.content,showModal,selectedRows,dispatch,navigate,token]);

  

  return (
    <>
      {loading?
      (<Loader/>):
      (
        <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
          <SideBar />

          <main className="page-content bg-gray-50 dark:bg-slate-800">
            <Header toggleSidebar={handleToggleSidebar}/>
            <div className="container-fluid relative px-3">
              <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                  <div>
                    <h5 className="text-lg font-semibold">Templates</h5>
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
                    {templatesData?templatesData.map((template) => (
                        template.status === "APPROVED"?(<div
                        className={`relative flex flex-col justify-between rounded-md shadow dark:shadow-gray-700 overflow-hidden min-h-[300px]  ${
                            selectedTemplate === template.id
                            ? " border-4  !important"
                            : "bg-white dark:bg-slate-900"
                        }`}
                        >
                        <div className="content p-6 flex-grow">
                            <div className="flex align-center justify-between mb-4 ">
                            <h6 className="text-15 font-bold">
                                {template.template_name}
                            </h6>
                            </div>
                            
                        <SimpleBar style={{ maxHeight: "200px"}} autoHide={false}>

                            <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.header_content}</pre>
                            <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.content}</pre>
                        
                            <div className="variables mb-4">
                            </div>
                        </SimpleBar>  
                        </div>
                        <div className="px-6 pb-6 relative bottom-0 left-0">
                            {
                                !isEmptyObject(selectedRows)&&
                                <button
                                    onClick={() => {
                                        handleTemplateSelection(template);
                                    }}
                                    className={`py-2 px-5 mx-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md  ${
                                    selectedTemplate === template.id
                                        ? " bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700"
                                        : "bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                                    }`}
                                >
                                    Select Template
                                </button>
                            }
                            {showModal && (
                            <div className="modal">
                                <div className="modal-content">
                                <span
                                    className="close -mt-5"
                                    onClick={handleCloseModal}
                                >
                                    &times;
                                </span>
                                <div className="section-title ">
                                    <h4 className="text-1xl font-semibold uppercase mb-3">
                                    {selectedTemplate.template_name}
                                    </h4>

                                <SimpleBar style={{ maxHeight: "50vh" }} autoHide={false}>
                                    <div className="w-full p-4">
                                    <form onSubmit={handleTemplateSave}>
                                    {selectedTemplate.header_type=="text"&&
                                    <>
                                    <label className=" font-medium">Header:</label>
                                        <textarea
                                        ref={textareaRefHeader}
                                        className="w-full p-4 my-3 bg-gray-100 dark:bg-gray-800 dark:text-white rounded-md border border-gray-300 dark:border-gray-700 overflow-hidden resize-none"
                                        onInput={adjustTextareaHeightHeader}
                                        value={renderTemplateHeader()}
                                        readOnly
                                        />
                                    </>}
                                        
                                        {selectedTemplate.header_type=="document"&&
                                        <>
                                            <label htmlFor="media" className="form-label font-medium">
                                            Header Document:
                                            </label>
                                        <div className="flex items-start  space-x-4 w-full">
                                        <div className=" flex-grow">
                                            {/* <label htmlFor="document_link" className="form-label font-medium">
                                            Document Link:
                                            </label> */}
                                            <div className="">
                                            <input
                                                type="text"
                                                className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name="document_link"
                                                id="document_link"
                                                onChange={handleDocumentLinkChange}
                                                value={headerDocumentLink}
                                                placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                            />
                                            </div>
                                        </div>
                                        <div className="text-gray-500 font-medium px-2 whitespace-nowrap py-4">OR</div>

                                        <div className="flex-shrink-0 w-auto flex flex-col">
                                        <div className="flex flex-col">
                                        <label
                                            htmlFor="document_upload"
                                            className="w-full  mt-2 rounded-md   py-2 px-3 h-10 border border-indigo-600 text-indigo-600 hover:border-indigo-400 hover:bg-indigo-300 hover:text-indigo-400 cursor-pointer flex items-center justify-center  whitespace-nowrap"
                                        >
                                            Choose file
                                        </label>
                                        {headerDocumentupload&&
                                            <div className=" text-indigo-600 text-sm mt-1 text-center">
                                            ({headerDocumentupload.name})
                                            </div>
                                        }
                                            <div className="">
                                            <input type="file" 
                                            onChange={handleDocumentFileChange} 
                                            ref={fileInputRef}                                      
                                            accept=".pdf"  
                                            name="document_upload"
                                            id="document_upload"
                                            className="hidden"/>
                                            </div>
                                        </div>
                                        </div>
                                        </div>  
                                        <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                        <div className="xl:col-span-12 md:col-span-12 mb-3">
                                            <label htmlFor="document_link" className="form-label font-medium">
                                            Document Name:
                                            </label>
                                            <div className="">
                                            <input
                                                type="text"
                                                className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name="document_name"
                                                id="document_name"
                                                onChange={(e) => setHeaderDocumentName(e.target.value)}
                                                value={headerDocumentName}
                                                placeholder="example.pdf"
                                                required
                                            />
                                            </div>
                                        </div>
                                        </div>  
                                        </>
                                        }                                
                                        {selectedTemplate.header_type=="image"&&
                                        <>
                                            <label htmlFor="media" className="form-label font-medium">
                                            Header Image:
                                            </label>
                                        <div className="flex items-start  space-x-4 w-full">
                                        <div className=" flex-grow">
                                            {/* <label htmlFor="document_link" className="form-label font-medium">
                                            Document Link:
                                            </label> */}
                                            <div className="">
                                            <input
                                                type="text"
                                                className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name="image_link"
                                                id="image_link" 
                                                onChange={handleImageLinkChange}
                                                value={headerImageLink}
                                                placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                            />
                                            </div>
                                        </div>
                                        <div className="text-gray-500 font-medium px-2 whitespace-nowrap py-4">OR</div>
                                        <div className="flex-shrink-0 w-auto flex flex-col">
                                        <div className="flex flex-col">
                                        <label
                                            htmlFor="image_upload"
                                            className="w-full  mb-3 mt-2 rounded-md   py-2 px-3 h-10 border border-indigo-600 text-indigo-600 hover:border-indigo-400 hover:bg-indigo-300 hover:text-indigo-400 cursor-pointer flex items-center justify-center  whitespace-nowrap"
                                        >
                                            Choose file
                                        </label>
                                        {headerImageupload&&
                                            <div className=" text-indigo-600 text-sm mt-1 text-center">
                                            ({headerImageupload.name})
                                            </div>
                                        }
                                            <div className="">
                                            <input type="file" 
                                            onChange={handleImageFileChange} 
                                            ref={fileInputRef}                                      
                                            accept="image/*"  
                                            name="image_upload"
                                            id="image_upload"
                                            className="hidden"/>
                                            </div>
                                        </div>
                                        </div>
                                        </div>  
                                        </>
                                        }                                


                                        
                                        <label className=" font-medium">Body:</label>
                                        <textarea
                                        ref={textareaRef}
                                        className="w-full p-4 my-3 bg-gray-100 dark:bg-gray-800 dark:text-white rounded-md border border-gray-300 dark:border-gray-700 overflow-hidden resize-none"
                                        onInput={adjustTextareaHeight}
                                        value={renderTemplate()}
                                        readOnly
                                        />
                                        <div className="py-3">
                                        {Object.keys(variablesHeader).length > 0&&<h2 className="text-lg font-semibold mb-4">Header Variables</h2>}
                                        {Object.keys(variablesHeader).map((key) => (
                                        <div key={key} className="py-2" >
                                            <label htmlFor={key}>
                                            {key.charAt(0).toUpperCase() +
                                                key.slice(1)}
                                            </label>
                                            <div className="">
                                            <input
                                                type="text"
                                                className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name={key}
                                                id={key}
                                                value={variablesHeader[key]}
                                                onChange={handleHeaderInputChange}
                                                required
                                            />
                                            </div>

                                        </div>
                                        ))}
                                        {Object.keys(variables).length > 0&&
                                        <>
                                        <div className="w-full col-span-12 mt-3 mb-3">
                                            <hr className="border-t border-gray-300 dark:border-gray-700" />
                                        </div>
                                        <h2 className="text-lg font-semibold mb-4">Body Variables</h2>
                                        </>}
                                        {Object.keys(variables).map((key) => (
                                        <div key={key} className="py-2">
                                            <label htmlFor={key}>
                                            {key.charAt(0).toUpperCase() +
                                                key.slice(1)}
                                            </label>
                                            <div className="">
                                            <input
                                                type="text"
                                                className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name={key}
                                                id={key}
                                                value={variables[key]}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            </div>

                                        </div>
                                        ))}
                                        </div>
                                        <div className="flex flex-col items-end space-y-4">
                                        {isAdding && (
                                        <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                        <div className="xl:col-span-6 md:col-span-6">
                                        <label htmlFor="website_name" className="">
                                            Website Name
                                        </label>
                                        <input
                                            type="text"
                                            id="website_name"
                                            name="website_name"
                                            value={websiteName}
                                            onChange={(e) => setWebsiteName(e.target.value)}
                                            className="form-input w-full py-2 px-3 h-10 mb-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                            placeholder="Enter website name"
                                        />
                                        </div>
                            
                                        <div className="xl:col-span-6 md:col-span-6">
                                        <label htmlFor="website_url" className="">
                                            Website URL
                                        </label>
                                        <input
                                            type="text"
                                            id="website_url"
                                            name="website_url"
                                            value={websiteURL}
                                            onChange={(e) => setWebsiteURL(e.target.value)}
                                            className="form-input w-full py-2 px-3 mb-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                            placeholder="Enter website URL"
                                        />
                                        </div>
                                        </div>
                                        )}  
                                        <div className="flex justify-start">                              
                                        <button
                                        type="submit"
                                        className="py-2 px-5 font-semibold tracking-wide border border-indigo-600 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700 transition-colors duration-300"                                  >
                                        Send Message
                                        </button>
                                        </div>
                                        </div>
                                    </form>
                                    </div>
                                </SimpleBar>
                                </div>
                                </div>
                            </div>
                            )}
                        </div>
                        </div>
                    ):null
                  )):null}
                </div>
              </div>
            </div>

            <Footer />
          </main>
        </div>
      )
    } 
    </>
  );
}

export default SelectTemplate;
