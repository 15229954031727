import React, { useState , useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import WhatsappChatUi from "./WhatsappChatUi";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useLocation,useNavigate } from 'react-router-dom';
import { Axios, ExternalAxios} from "./axiosInstances";
import Loader from "./Loader";
import { storeToken, storeUserInfo } from "../features/userInfoSlice";
import { customerStore } from "../features/customersDataSlice";
import Swal from 'sweetalert2'
import ReactApexChart from "react-apexcharts";
import { format } from 'date-fns';

function Home() {

  const [loading,setLoading]=useState(false)
  const [customerCategory,setCustomerCategory]=useState()
  const [messageCredit,setMessageCredit]=useState(0)
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [upcomingAnniversary, setUpcomingAnniversary] = useState([]);
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessageData, setChatMessageData] = useState([]);
  const [recentChatList, setRecentChatList] = useState([]);
  const [chatAnalysisData, setChatAnalysisData] = useState([]);

  const initialChartOptions = {
          series: [{
             name: 'Sent Messages',
             data: [],
         }, {
             name: 'Received Messages',
             data: []
         }],
         chart: {
             type: 'bar',
             height: 350,
             toolbar: {
                 show: false,
                 autoSelected: 'zoom'
             },
         },
         grid: {
             strokeDashArray: 5,
       
         },
         plotOptions: {
             bar: {
                 borderRadius: 5,
                 horizontal: false,
                 columnWidth: '40%',
                 endingShape: 'rounded',
                 colors: {
                  ranges: [{
                      from: 0,
                      to: 0,
                      color: 'rgba(0,0,0,0)' // Fully transparent
                  }]
              }
             },
         },
         dataLabels: {
             enabled: false
         },
         stroke: {
             show: true,
             width: 2,
             colors: ['transparent']
         },
         colors: ['#4f46e5', '#10b981'],
         xaxis: {
             categories:  [],
             title: {
               text: 'Months',
               style: {
                 fontSize: '14px',
                 fontWeight: 500,
               }
             }
         },
         yaxis: {
             title: {
                 text: 'Message Count',
     
                 style: {
                     colors: ['#8492a6'],
                     fontSize: '16px',
                     fontFamily: 'Nunito, sans-serif',
                     fontWeight: 600,
                 },
             },
            min: 0,
            forceNiceScale: true,
            floating: false,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true
            }

         },
         fill: {
             opacity: 1,
         },
         tooltip: {
             y: {
               formatter: function (val) {
                 return val.toString()  // Removed the "$" since these are message counts
               }
             }
         }
 };
 
  const [chartOptions, setChartOptions] = useState(initialChartOptions);

  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const customerList = useSelector((state) => state.customerList.customerList);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };


  const handleOpenChatModal = () => {
    setShowChatModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleCloseChatModal = () => {
      setShowChatModal(false);
      document.body.classList.remove("overflow");
  };

  const handleChatClick= (message_to) => {
    handleOpenChatModal()
    fetchChatMessageData(message_to)
    // handleAddTemplateOpenModal()
  }

  const customerCategoryData= async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await ExternalAxios.post(
        "/get-category-list",
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/list_category/1/",
        {company_id:userInfo.data.iCompanyID,
        user_token:token},
        config
      );

      if (res.data && res.data.status) {
        const categories = JSON.parse(res.data.data[0].category)
        setCustomerCategory(categories);
      } else {
        setCustomerCategory([]);
      } 

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const getCustomerCount = (category) => 
    customerList.data.filter(customer => customer.customer_type === category).length;

  const handleViewMore = (category) => {
    navigate(`/customer-listing/${category}`);
  };

  // const handleMessageCreditViewMore = () => {
  //   Swal.fire({
  //     title: "+91 9687204072",
  //     text: "Call on this number for recharge",
  //     icon: 'info',
  //     confirmButtonText: 'Ok'
  //     })
  // };


  const CustomerListData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await ExternalAxios.post(
        //   "/api/v1/customerviewset/list_users/",
        "/get-customer",
        {
          // page:1,
          // limit:10,
          company_id:userInfo.data.iCompanyID,
          user_token:token
        },
        config
      );
      
      if (res.data&&res.data.status) {
        dispatch(customerStore(res?.data))
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchChatMessageData = async (customerPhone) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/retrieve_chat/${userInfo.data.iCompanyID}/${customerPhone}`,
        config
      );
      
      if (res.data) {
        setChatMessageData(res?.data);
        console.log("chat info",res.data)
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // const UserInfoData = async () => {
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     };
  //       const res = await axios.post(
  //       //   "/api/v1/customerviewset/list_users/",
  //       "https://suvarnakarsoftware.com/api/v1/get-customer",
  //       {
  //         user_token:"P29883e6e97c382e113ae3a821",
  //       },
  //       config
  //     );
      
  //     if (res.data) {
  //       // setCustomerList(res?.data);
  //       dispatch(storeUserInfo(res?.data))
  //       console.log("customer list ",res.data)
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Response Error:", error.response.data);
  //       console.error("Status Code:", error.response.status);
  //     } else if (error.request) {
  //       console.error("Request Error:", error.request);
  //     } else {
  //       console.error("Error:", error.message);
  //     }
  //   }
  // };


  const messageCreditData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/message_credit/${userInfo.data.iCompanyID}/`,
        config
      );
      
      if (res.data&& res.data[0].message_credit) {
        setMessageCredit(res?.data[0].message_credit)
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const getRecentChatList = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/get_recent_chats/${userInfo.data.iCompanyID}/`,
        config
      );
      
      if (res.data) {
        setRecentChatList(res?.data)
        console.log(res.data)
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchChartAnalysisData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const {data} = await Axios.get(
        `/chatviewset/chat_messages_analysis/${userInfo.data.iCompanyID}/`,
        config
      );
      
      if (data && data.series && data.labels) {
        setChartOptions(prev => ({
          ...prev,
          series: data.series,
          xaxis: {
            ...prev.xaxis,
            categories: data.labels
          }
        }));
        setChatAnalysisData(data);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const getUpcommingDatesCustomerList = () => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7); 
  
    const upcomingBirthdaysList= customerList.data?.filter((customer) => {
      if (!customer.birthday_date) {
        return false; 
      }
      const birthdate = new Date(customer.birthday_date); 
      const thisYearBirthday = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
  
      return thisYearBirthday >= today && thisYearBirthday <= nextWeek;
    });
    setUpcomingBirthdays(upcomingBirthdaysList)

    const upcomingAnniversaryList= customerList.data?.filter((customer) => {
      if (!customer.anniversary_date) {
        return false; 
      }
      const anniversary = new Date(customer.anniversary_date); 
      const thisYearAnniversary = new Date(today.getFullYear(), anniversary.getMonth(), anniversary.getDate());
      
      return thisYearAnniversary >= today && thisYearAnniversary <= nextWeek;
    });
    setUpcomingAnniversary(upcomingAnniversaryList)
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userToken = queryParams.get('user_token');
    
    
    const verifyToken = async (token) => {
      try {
        const response = await Axios.post('/verify-token/', { token });

          if(response.data&&response.status===200){
            dispatch(storeUserInfo(response.data.data))
            dispatch(storeToken(response.data.token))
            navigate('/');
          }else{
            navigate('/error')
          }

      } catch (error) {
        navigate('/error');
        
      } finally {
        setLoading(false);

      }
    };


    const handleTokenVerification = async () => {

      setLoading(true)

      if (userToken) {
        await verifyToken(userToken);

      } else if (token) {
        setLoading(false)
        try {
          navigate('/');
          if (customerList.length === 0) {
            await CustomerListData();
          }
          await customerCategoryData();
          await messageCreditData();
          await getRecentChatList();
          await fetchChartAnalysisData();
          if (customerList.data && customerList.data.length > 0) {
            getUpcommingDatesCustomerList();
          }

        } catch (error) {
          console.log(error)
          navigate('/error');

        } finally {
          setLoading(false)
          
        }

      } else {
        navigate('/error');
        setLoading(false)
      }
    };
  
    handleTokenVerification();
    // if(!userInfo){
    //   UserInfoData()
    // }


    // const handleTabClose = async (event) => {
    //   event.preventDefault();

    //   // Make an API call to clear session and cache
    //   await axios.post('http://127.0.0.1:8000/api/v1/clear-session/');

    //   // Required for older browsers
    //   return (event.returnValue = 'Are you sure you want to leave?');
    // };

    // window.addEventListener('beforeunload', handleTabClose);

    // return () => {
    //   window.removeEventListener('beforeunload', handleTabClose);
    // };

  },[dispatch, navigate, location.search, token, customerList.length]);

  return (
    <>
      {loading?
      (<Loader/>):
       ( 
        <div className={`page-wrapper ${isSidebarToggled ? "toggled" : ""}`}>
          <SideBar />

          <main className="page-content bg-gray-50 dark:bg-slate-800">
            <Header toggleSidebar={handleToggleSidebar} />

            <div className="container-fluid relative px-3">
              <div className="layout-specing">
                <div className="flex justify-between items-center">
                  <div>
                    <h5 className="text-xl font-bold text-red-500">Hello, {userInfo&&userInfo.data&&userInfo.data.vCompanyUsername}</h5>
                    <h6 className="text-slate-400 font-semibold">Welcome!</h6>
                  </div>

                  {/* <div className="flex items-center">
                    <div className="position-relative">
                      <select
                        className="form-select form-input w-full py-2 h-10 bg-white dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-gray-200 dark:border-gray-800 dark:focus:border-gray-700 focus:ring-0"
                        id="yearchart"
                      >
                        <option value="Y" selected>
                          Yearly
                        </option>
                        <option value="M">Monthly</option>
                        <option value="W">Weekly</option>
                        <option value="T">Today</option>
                      </select>
                    </div>

                    <a href="" className="ms-1">
                      <span className="py-[7px] px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:inline-block hidden">
                        <i className="uil uil-export"></i> Export
                      </span>

                      <span className="h-10 w-10 items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white sm:hidden inline-flex">
                        <i className="uil uil-export"></i>
                      </span>
                    </a>
                  </div> */}
                </div>

                <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 mt-6 gap-6">
                {/* <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 flex flex-col justify-between h-full">
                    <div className="p-5 flex items-center flex-grow" >
                      <span className="ms-3">
                        <span className=" font-black-800 font-semibold text-xl block mt-auto">
                           MESSAGE CREDIT
                        </span>
                        <span className="flex items-center justify-between mt-1">
                          <span className="text-xl font-semibold">
                            <span className="counter-value" data-target="45890">
                              {messageCredit}
                            </span>
                          </span>
                         
                        </span>
                      </span>
                    </div>

                    <div className="px-5 py-4 bg-gray-50 dark:bg-slate-800">
                      <button
                        
                        onClick={() => handleMessageCreditViewMore()}
                        className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-indigo-600 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600 dark:after:bg-white duration-500"
                      >
                        Add Credit <i className="uil uil-arrow-right"></i>
                      </button>
                    </div>
                  </div> */}
                {customerCategory&&
                  customerCategory.map(category => (
                  <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 flex flex-col justify-between h-full">
                    <div className="p-5 flex items-center flex-grow">
                      {/* <span className="flex justify-center items-center rounded-md size-14 min-w-[56px] bg-indigo-600/5 dark:bg-indigo-600/10 shadow shadow-indigo-600/5 dark:shadow-indigo-600/10 text-indigo-600">
                        <i data-feather="users" className="h-5 w-5"></i>
                      </span> */}

                      <span className="ms-3">
                        <span className=" font-black-800 font-semibold text-xl block mt-auto">
                          {category?.toUpperCase()}
                        </span>
                        <span className="flex items-center justify-between mt-1">
                          <span className="text-xl font-semibold">
                            <span className="counter-value" data-target="45890">
                              {getCustomerCount(category)}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div className="px-5 py-4 bg-gray-50 dark:bg-slate-800">
                      <button
                        onClick={() => handleViewMore(category)}
                        className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-indigo-600 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600 dark:after:bg-white duration-500"
                      >
                        View Data <i className="uil uil-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                  ))
                }
               
                </div>

                <div className="grid  lg:grid-cols-12  grid-cols-1 mt-6 gap-6">
                  <div className="lg:col-span-6" id="tables">
                    {/* <h5 className="font-bold text-xl mb-6">Tables</h5>       */}
                      {/* <div className="grid grid-cols-1 gap-6">
                        <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
                          <div className="p-5">
                              <h5 className="text-lg font-semibold">Upcoming Birthdays</h5>
                          </div>
                          <div className="p-5 border-t border-gray-100 dark:border-slate-800">
                            <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                              <table className="w-full text-start">
                                <thead>
                                  <tr>
                                      <th className="px-4 py-5 text-start">Name</th>
                                      <th className="px-4 py-5 text-end">Birthdays date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {upcomingBirthdays&&upcomingBirthdays.map((customer) => (
                                  <tr className="border-t border-gray-100 dark:border-gray-700">
                                      <td className="p-4"><a href="" className="">{customer.firstName}</a></td>
                                      <td className="p-4 text-end">{format(new Date(customer.birthday_date), 'MMMM dd, yyyy')}</td>
                                  </tr>                         
                                ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                  <div className="lg:col-span-6" id="tables">
                    {/* <h5 className="font-bold text-xl mb-6">Tables</h5>       */}
                      {/* <div className="grid grid-cols-1 gap-6">
                        <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
                          <div className="p-5">
                              <h5 className="text-lg font-semibold">Upcoming Anniversary</h5>
                          </div>
                          <div className="p-5 border-t border-gray-100 dark:border-slate-800">
                            <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                              <table className="w-full text-start">
                                <thead>
                                  <tr>
                                      <th className="px-4 py-5 text-start">Name</th>
                                      <th className="px-4 py-5 text-end">Anniversary date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {upcomingAnniversary&&upcomingAnniversary.map((customer) => (
                                  <tr className="border-t border-gray-100 dark:border-gray-700">
                                      <td className="p-4"><a href="" className="">{customer.firstName}</a></td>
                                      <td className="p-4 text-end"> {format(new Date(customer.anniversary_date), 'MMMM dd, yyyy')}</td>
                                  </tr>                         
                                ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                  <div className="lg:col-span-12" id="tables">
                    {/* <h5 className="font-bold text-xl mb-6">Tables</h5>       */}
                      <div className="grid grid-cols-1 gap-6">
                        <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
                          <div className="p-5">
                              <h5 className="text-lg font-semibold">Recent Reply</h5>
                          </div>
                          <div className="p-5 border-t border-gray-100 dark:border-slate-800  h-full flex flex-col">
                            <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md flex-grow">
                              <table className="w-full text-start">
                                <thead>
                                  <tr>
                                      <th className="px-4 py-5 text-start">Name</th>
                                      <th className="px-4 py-5 text-end">Chat</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {recentChatList&&recentChatList.map((customer) => (
                                  <tr className="border-t border-gray-100 dark:border-gray-700">
                                      <td className="p-4"><a href="" className="">{customer.recipient_name}</a></td>
                                      {/* <td className="p-4"><a href="" className="">abc</a></td> */}
                                      <td className="px-4  py-2 text-end ">
                                        <button
                                          type='button'
                                          className='py-1 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md z-10'
                                          // onClick={() => handleChatClick(8200502024)}
                                          onClick={() => handleChatClick(customer.message_to)}
                                        >
                                          Chat
                                        </button>
                                      </td>
                                      {/* <td className="p-4 text-end"> {format(new Date(customer.anniversary_date), 'MMMM dd, yyyy')}</td> */}
                                  </tr>                         
                                ))} 
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  {showChatModal && (
                        <Modal isOpen={handleOpenChatModal} onClose={handleCloseChatModal} title="Chat">
                            <div className="w-full p-4">
                              <WhatsappChatUi chatMessageData={chatMessageData}/>
                            </div>
                        </Modal>
                        
                      )}
                    <div className="lg:col-span-12">
                    <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                      <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
                        <h6 className="text-lg font-semibold">
                          Message Count Analytics
                        </h6>

                        <div className="position-relative">
                        </div>
                      </div>
                      <div className="apex-chart px-6 pb-6">
                        <ReactApexChart options={chartOptions} series={chartOptions.series} type="bar" height={300} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="lg:col-span-4">
                    <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                      <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
                        <h6 className="text-lg font-semibold">
                          Customers by Country
                        </h6>

                        <div className="dropdown relative">
                          <button
                            data-dropdown-toggle="dropdown"
                            className="dropdown-toggle items-center"
                            type="button"
                          >
                            <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-800/5 hover:bg-gray-800/10 dark:bg-gray-800 border border-gray-800/5 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                              <i className="mdi mdi-dots-vertical"></i>
                            </span>
                          </button>

                          <div
                            className="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 hidden"
                            onClick="event.stopPropagation();"
                          >
                            <ul className="py-2 text-start">
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  Profile
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  Profile Settings
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="p-6 border-b border-gray-100 dark:border-gray-800">
                        <div id="map" className="w-full h-[236px]"></div>
                      </div>

                      <div className="p-6">
                        <ul className="list-none flex">
                          <li className="inline-block w-1/2">
                            <span className="text-indigo-600 font-semibold">
                              Canada
                            </span>
                            :<span className="text-slate-400 ms-2">12468</span>
                          </li>
                          <li className="inline-block w-1/2">
                            <span className="text-indigo-600 font-semibold">
                              Greenland
                            </span>
                            :<span className="text-slate-400 ms-2">12468</span>
                          </li>
                        </ul>
                        <ul className="list-none flex">
                          <li className="inline-block w-1/2">
                            <span className="text-indigo-600 font-semibold">
                              Russia
                            </span>
                            :<span className="text-slate-400 ms-2">12468</span>
                          </li>
                          <li className="inline-block w-1/2">
                            <span className="text-indigo-600 font-semibold">
                              Palestine
                            </span>
                            :<span className="text-slate-400 ms-2">12468</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6">
                  <div className="xl:col-span-5 lg:col-span-12">
                    <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                      <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
                        <h6 className="text-lg font-semibold">Orders</h6>

                        <a
                          href=""
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600 dark:after:bg-white duration-500"
                        >
                          View orders <i className="uil uil-arrow-right"></i>
                        </a>
                      </div>

                      <div
                        className="relative overflow-x-auto block w-full max-h-[400px]"
                        data-simplebar
                      >
                        <table className="w-full text-start">
                          <thead className="text-base">
                            <tr>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[100px]">
                                No.
                              </th>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[128px]">
                                ID
                              </th>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[128px]">
                                Date
                              </th>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[128px]">
                                Price
                              </th>
                              <th className="text-end font-semibold text-[15px] p-4 min-w-[128px]">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                01
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw001
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  10th Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$253</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-emerald-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  Delivered
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                02
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw002
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  13th Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$123</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  New Order
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                03
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw003
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  18th Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$245</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-red-600/10 dark:bg-red-600/20 border border-red-600/10 dark:border-red-600/20 text-red-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  Return
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                04
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw004
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  21st Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$157</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-gray-500/5 border border-gray-500/5 text-gray-500 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  Cancel
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                05
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw005
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  22nd Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$62</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  New Order
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                06
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw006
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  23rd Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$456</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-emerald-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  Delivered
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                07
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                #tw007
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">
                                  24th Aug 2023
                                </span>
                              </td>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-slate-400">$478</span>
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-emerald-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                                  Delivered
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="xl:col-span-4 lg:col-span-6">
                    <div className="rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                      <div className="flex justify-between items-center border-b border-gray-100 dark:border-gray-800 p-4">
                        <div className="flex">
                          <img
                            src="assets/images/client/01.jpg"
                            className="h-11 w-11 rounded-full shadow dark:shadow-gray-700"
                            alt=""
                          />
                          <div className="overflow-hidden ms-3">
                            <a
                              href="javascript:void(0)"
                              className="block font-semibold text-truncate"
                            >
                              Calvin Carlo
                            </a>
                            <span className="text-slate-400 flex items-center text-sm">
                              <span className="bg-green-600 text-white text-[10px] font-bold rounded-full size-2 me-1"></span>{" "}
                              Online
                            </span>
                          </div>
                        </div>

                        <div className="dropdown relative">
                          <button
                            data-dropdown-toggle="dropdown"
                            className="dropdown-toggle items-center"
                            type="button"
                          >
                            <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-800/5 hover:bg-gray-800/10 dark:bg-gray-800 border border-gray-800/5 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                              <i className="mdi mdi-dots-vertical"></i>
                            </span>
                          </button>

                          <div
                            className="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 hidden"
                            onClick="event.stopPropagation();"
                          >
                            <ul className="py-2 text-start">
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  <i className="mdi mdi-account-outline"></i>{" "}
                                  Profile
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  <i className="mdi mdi-cog-outline"></i>{" "}
                                  Profile Settings
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  className="block font-medium py-1 px-4 text-slate-400 dark:text-white/70 hover:text-slate-900 dark:hover:text-white"
                                >
                                  <i className="mdi mdi-trash-can-outline"></i>{" "}
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <ul
                        className="p-4 list-none mb-0 max-h-[350px] bg-[url('../../assets/images/bg-chat.png')] bg-no-repeat bg-center bg-cover"
                        data-simplebar
                      >
                        <li>
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Hey Cristina
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  59 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="text-end">
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative order-2">
                                <img
                                  src="assets/images/client/05.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 end-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="me-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Hello Calvin
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  45 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="text-end">
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative order-2">
                                <img
                                  src="assets/images/client/05.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 end-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="me-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  How can i help you?
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  44 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Nice to meet you
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  42 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Hope you are doing fine?
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  40 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="text-end">
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative order-2">
                                <img
                                  src="assets/images/client/05.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 end-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="me-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  I'm good thanks for asking
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  38 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  I am intrested to know more about your prices
                                  and services you offer
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  35 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="text-end">
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative order-2">
                                <img
                                  src="assets/images/client/05.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 end-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="me-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Sure please check below link to find more
                                  useful information{" "}
                                  <a
                                    href="https://shreethemes.in/techwind/"
                                    target="_blank"
                                    className="text-indigo-600"
                                  >
                                    https://shreethemes.in/techwind/
                                  </a>
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  29 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Thank you 😊
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  26 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="text-end">
                          <div className="inline-block">
                            <div className="flex mb-3">
                              <div className="relative order-2">
                                <img
                                  src="assets/images/client/05.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 end-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="me-2 max-w-lg">
                                <p className="bg-white dark:bg-slate-900 text-slate-400 text-sm shadow dark:shadow-gray-700 px-3 py-2 rounded mb-1">
                                  Welcome
                                </p>
                                <span className="text-slate-400 text-sm">
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  15 min ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inline-block">
                            <div className="flex items-center mb-3">
                              <div className="relative">
                                <img
                                  src="assets/images/client/01.jpg"
                                  className="size-9 min-w-[36px] rounded-full shadow dark:shadow-gray-700"
                                  alt=""
                                />
                                <span className="absolute top-0.5 start-0.5 flex items-center justify-center bg-green-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-green-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
                              </div>

                              <div className="ms-2 max-w-lg">
                                <p className="text-slate-400 text-sm rounded mb-1">
                                  Frank Williams is typing
                                  <span className="animate-typing ms-1">
                                    <span className="dot inline-block size-1 bg-slate-400 -mr-px opacity-60 rounded-full"></span>
                                    <span className="dot inline-block size-1 bg-slate-400 -mr-px opacity-60 rounded-full"></span>
                                    <span className="dot inline-block size-1 bg-slate-400 -mr-px opacity-60 rounded-full"></span>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="p-2 border-t border-gray-100 dark:border-gray-800">
                        <div className="flex ">
                          <input
                            type="text"
                            className="form-input w-full py-2 px-3 h-9 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                            placeholder="Enter Message..."
                          />

                          <div className="min-w-[125px] text-end">
                            <a
                              href="#"
                              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[16px] text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                            >
                              <i className="mdi mdi-send"></i>
                            </a>
                            <a
                              href="#"
                              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[16px] text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                            >
                              <i className="mdi mdi-emoticon-happy-outline"></i>
                            </a>
                            <a
                              href="#"
                              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[16px] text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                            >
                              <i className="mdi mdi-paperclip"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="xl:col-span-3 lg:col-span-6">
                    <div className="rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                      <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
                        <h6 className="text-lg font-semibold">
                          Top Products / Items
                        </h6>

                        <a
                          href=""
                          className="text-slate-400 hover:text-indigo-600 dark:text-white/70 dark:hover:text-white text-[20px]"
                        >
                          <i className="mdi mdi-swap-vertical"></i>
                        </a>
                      </div>

                      <div
                        className="relative overflow-x-auto block w-full max-h-[400px]"
                        data-simplebar
                      >
                        <table className="w-full text-start">
                          <thead className="text-base">
                            <tr>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[150px]">
                                Products
                              </th>
                              <th className="text-start font-semibold text-[15px] p-4 min-w-[100px]">
                                Earnings
                              </th>
                              <th className="text-end font-semibold text-[15px] p-4 min-w-[80px]">
                                Progress
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Techwind
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $4120
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-emerald-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-arrow-growth"></i> 5.5%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Landrick
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $5648
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-red-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-chart-down"></i> 15.8%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Hously
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $456
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-emerald-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-arrow-growth"></i> 1.3%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Jobstack
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $546
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-red-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-chart-down"></i> 1.54%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Giglink
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $124
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-red-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-chart-down"></i> 8.5%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Upwind
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $1545
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-emerald-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-arrow-growth"></i> 6.4%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Fronter
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $1215
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-red-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-chart-down"></i> 4.8%
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <th className="text-start border-t border-gray-100 dark:border-gray-800 p-4 font-semibold">
                                Doctris
                              </th>
                              <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                $2321
                              </td>
                              <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                <span className="text-emerald-600 text-sm ms-1 font-semibold">
                                  <i className="uil uil-arrow-growth"></i> 4.1%
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <Footer />
          </main>
        </div>
        )
      }
   
    </>
  );
}

export default Home;
