import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Axios, ExternalAxios } from "./axiosInstances";
import Loader from "./Loader";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import Swal from 'sweetalert2'

function AutoMessaging() {

    const [loading, setLoading] = useState(false)
    const [isBirthdayMessageEnabled, setIsBirthdayMessageEnabled] = useState(false);
    const [isAnniversaryMessageEnabled, setIsAnniversaryMessageEnabled] = useState(false);
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [birthdayTemplate, setBirthdayTemplate] = useState(null);
    const [anniversaryTemplate, setAnniversaryTemplate] = useState(null);
    const [autoMessageTemplateType, setAutoMessageTemplateType] = useState(null);
    const [templatesData, setTemplatesData] = useState();

    const [showVariablesInput, setShowVariablesInput] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [variableValues, setVariableValues] = useState({});
    const [variableExamples, setVariableExamples] = useState({});

    const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
    const token = useSelector((state) => state.userInfo.token);
    const userInfo = useSelector((state) => state.userInfo.userInfo);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleShowTemplateModal = () => {
        setShowTemplateModal(true);
        document.body.classList.remove("overflow-hidden");
    };

    const handleCloseTemplateModal = () => {
        setSelectedTemplate(null);
        setShowVariablesInput(false);
        setAutoMessageTemplateType(null);
        setShowTemplateModal(false);
        document.body.classList.remove("overflow");
    };

    const templatesDataFetch = async () => {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };

            const res = await Axios.get(
                `/templateviewset/list_templates/?company_id=${userInfo.data.iCompanyID}`,
                config
            );

            if (res.data.template_list) {
                setTemplatesData(res?.data.template_list);
            }

        } catch (error) {
            if (error.response) {
                console.error("Response Error:", error.response.data);
                console.error("Status Code:", error.response.status);
            } else if (error.request) {
                console.error("Request Error:", error.request);
            } else {
                console.error("Error:", error.message);
            }
        }
    };

    const handleBirthdayStatusChange = async () => {
        const newStatus = !isBirthdayMessageEnabled;

        if (newStatus) {
            handleShowBirthdayModal();
        } else {
            try {
                await updateAutoMessageSettings({
                    status: false,
                    category: 'BIRTHDAY',
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Auto messaging turned off for birthday!',
                    showConfirmButton: false,
                    timer: 1500
                });
                setBirthdayTemplate(null);
                setIsBirthdayMessageEnabled(false);
            } catch (error) {
                console.error('Failed to update template settings:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to configure template',
                    text: 'Please try again later.',
                });
            }
        }
    };

    const handleShowBirthdayModal = async () => {
        await templatesDataFetch();
        setAutoMessageTemplateType('BIRTHDAY');
        handleShowTemplateModal();
    }

    const handleAnniversaryStatusChange = async () => {
        const newStatus = !isAnniversaryMessageEnabled;

        if (newStatus) {
            handleShowAnniversaryModal();
        } else {
            try {
                await updateAutoMessageSettings({
                    status: false,
                    category: 'ANNIVERSARY',
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Auto messaging turned off for anniversary!',
                    showConfirmButton: false,
                    timer: 1500
                });
                setAnniversaryTemplate(null);
                setIsAnniversaryMessageEnabled(false);
            } catch (error) {
                console.error('Failed to update template settings:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to configure template',
                    text: 'Please try again later.',
                });
            }
        }
    };

    const handleShowAnniversaryModal = async () => {
        await templatesDataFetch();
        setAutoMessageTemplateType('ANNIVERSARY');
        handleShowTemplateModal();
    }

    const handleTemplateSelect = async (template) => {
        setSelectedTemplate(template);
        const { variables, examples } = getUniqueVariables(template);
        setVariableValues(variables);
        setVariableExamples(examples);
        setShowVariablesInput(true);
    };

    const extractVariables = (text) => {
        const regex = /{{(.*?)}}/g;
        const matches = [...text.matchAll(regex)];
        return matches.map(match => match[1].trim());
    };

    const getUniqueVariables = (template) => {
        const headerVars = template?.header_content ? extractVariables(template.header_content) : [];
        const contentVars = template?.content ? extractVariables(template.content) : [];

        // Combine and remove duplicates
        const allVars = [...new Set([...headerVars, ...contentVars])];

        // Get example values from sample_values_json if available
        const examples = {
            ...(template?.sample_values_json?.header_example || {}),
            ...(template?.sample_values_json?.content_example || {})
        };

        // Create initial empty values for all variables
        const variables = allVars.reduce((acc, variable) => {
            // Only add to variables if it's not customer_name
            if (variable !== 'customer_name') {
                // If it's company_name, use the company name from userInfo
                if (variable === 'company_name') {
                    acc[variable] = userInfo?.data?.vCompanyName || '';
                } else {
                    acc[variable] = '';
                }
            }
            return acc;
        }, {});

        return {
            variables,
            examples
        };
    };

    const handleVariableInputChange = (name, value) => {
        setVariableValues(prev => ({
            ...prev,
            [name]: value
        }));
        setVariableExamples(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const prepareSubmissionData = () => {
        const headerVars = selectedTemplate?.header_content ? extractVariables(selectedTemplate.header_content) : [];
        const contentVars = selectedTemplate?.content ? extractVariables(selectedTemplate.content) : [];

        // Function to remove duplicates while maintaining order
        const removeDuplicates = (arr) => {
            const seen = new Set();
            return arr.filter(item => {
                if (seen.has(item)) {
                    return false;
                }
                seen.add(item);
                return true;
            });
        };

        // Remove duplicates from headerVars and contentVars
        const uniqueHeaderVars = removeDuplicates(headerVars);
        const uniqueContentVars = removeDuplicates(contentVars);

        // Create arrays based on the template structure
        const headerValues = uniqueHeaderVars.map(varName => {
            if (varName === 'customer_name') {
                return 'customer_name';
            } else {
                return variableValues[varName] || '';
            }
        });

        const contentValues = uniqueContentVars.map(varName => {
            if (varName === 'customer_name') {
                return 'customer_name';
            } else {
                return variableValues[varName] || '';
            }
        });

        return {
            header_values: headerValues,
            content_values: contentValues
        };
    };

    const handleUpdateAutoMessagingTemplateData = (e) => {
        e.preventDefault();
        const { header_values, content_values } = prepareSubmissionData();
        handleVariablesSubmit({
            template_id: selectedTemplate.id,
            header_values: header_values,
            content_values: content_values,
            category: autoMessageTemplateType
        });
    };

    const handleVariablesSubmit = async (variables) => {
        try {
            await updateAutoMessageSettings({
                status: true,
                template_id: variables.template_id,
                category: variables.category,
                header_values: variables.header_values,
                content_values: variables.content_values
            });

            if (variables.category === 'BIRTHDAY') {
                setIsBirthdayMessageEnabled(true);
                setBirthdayTemplate(selectedTemplate);
            } else if (variables.category === 'ANNIVERSARY') {
                setIsAnniversaryMessageEnabled(true);
                setAnniversaryTemplate(selectedTemplate);
            }

            setShowVariablesInput(false);
            setSelectedTemplate(null);
            setShowTemplateModal(false);
            Swal.fire({
                icon: 'success',
                title: 'Template configured successfully!',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error('Failed to update template settings:', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to configure template',
                text: 'Please try again later.',
            });
        }
    };

    const updateAutoMessageSettings = async (data) => {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            const response = await Axios.post('/auto-messaging/update_auto_message_settings/',
                {
                    company_id: userInfo.data.iCompanyID,
                    ...data
                },
                config
            );

            if (!response.data && response.status !== 200) {
                throw new Error('Failed to update settings');
            }

            return;
        } catch (error) {
            throw error;
        }
    };

    const fetchAutoMessageSettings = async () => {
        try {
            setLoading(true);
            const response = await Axios.get(
                `/auto-messaging/get_auto_message_settings/?company_id=${userInfo.data.iCompanyID}`
            );

            const { events } = response.data;

            // Handle birthday settings
            if (events.BIRTHDAY) {
                setIsBirthdayMessageEnabled(true);
                setBirthdayTemplate({
                    ...events.BIRTHDAY.template,
                    additional_context: events.BIRTHDAY.additional_context
                });
            }

            // Handle anniversary settings
            if (events.ANNIVERSARY) {
                setIsAnniversaryMessageEnabled(true);
                setAnniversaryTemplate({
                    ...events.ANNIVERSARY.template,
                    additional_context: events.ANNIVERSARY.additional_context
                });
            }
        } catch (error) {
            console.error('Failed to fetch auto message settings:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to load auto message settings'
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!token) {
            navigate('/error')
            return;
        }
        fetchAutoMessageSettings()
    }, [dispatch, navigate, token]);

    return (
        <>
            {loading ?
                (<Loader />) :
                (
                    <div className={`page-wrapper ${isSidebarToggled ? "toggled" : ""}`}>
                        <SideBar />

                        <main className="page-content bg-gray-50 dark:bg-slate-800">
                            <Header toggleSidebar={handleToggleSidebar} />

                            <div className="container-fluid relative px-3">
                                <div className="layout-specing">
                                    <div>
                                        <h5 className="text-lg font-semibold pb-4">Auto Messaging</h5>
                                    </div>
                                    <div className="flex flex-wrap items-start gap-5" style={{ alignItems: 'flex-start' }}>
                                        <div className="space-y-6 flex-1 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 p-5">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h3 className="text-lg font-semibold">Birthday Messages</h3>
                                                    <p className="text-sm text-gray-500">
                                                        Enable automatic birthday messages for your customers
                                                    </p>
                                                </div>

                                                {/* Custom Toggle Switch */}
                                                <label className="relative inline-block w-16 h-8 cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="hidden"
                                                        checked={isBirthdayMessageEnabled}
                                                        onChange={handleBirthdayStatusChange}
                                                    />
                                                    <div className={`
                                ${isBirthdayMessageEnabled ? 'bg-blue-600' : 'bg-stone-200'}
                                w-16 h-8 rounded-full transition-colors duration-200 ease-in-out
                                relative
                              `} style={{ background: "gray" }}>
                                                        <div className={`
                                  absolute w-6 h-6 rounded-full bg-white transition-transform duration-200 ease-in-out
                                  ${isBirthdayMessageEnabled ? 'left-9' : 'left-1'}
                                `} style={{ top: '12%', left: '10%' }}></div>
                                                        <span className={`
                                  absolute text-xs font-medium top-2
                                  ${isBirthdayMessageEnabled ? 'left-2 text-white' : 'right-2 text-gray-700'}
                                `} style={{ top: '25%', right: '10%' }}>
                                                            {isBirthdayMessageEnabled ? 'ON' : 'OFF'}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>

                                            {isBirthdayMessageEnabled && birthdayTemplate && (
                                                <div className="flex flex-wrap gap-4 items-center justify-between bg-gray-50 p-4 rounded-lg">
                                                    <div>
                                                        <p className="font-medium">Selected Template:</p>
                                                        <p className="text-sm text-gray-600">{birthdayTemplate.template_name}</p>
                                                    </div>
                                                    <button
                                                        onClick={handleShowBirthdayModal}
                                                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium
                                        hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    >
                                                        Change Template
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="space-y-6 flex-1 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 p-5">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h3 className="text-lg font-semibold">Anniversary Messages</h3>
                                                    <p className="text-sm text-gray-500">
                                                        Enable automatic anniversary messages for your customers
                                                    </p>
                                                </div>

                                                {/* Custom Toggle Switch */}
                                                <label className="relative inline-block w-16 h-8 cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="hidden"
                                                        checked={isAnniversaryMessageEnabled}
                                                        onChange={handleAnniversaryStatusChange}
                                                    />
                                                    <div className={`
                                ${isAnniversaryMessageEnabled ? 'bg-blue-600' : 'bg-stone-200'}
                                w-16 h-8 rounded-full transition-colors duration-200 ease-in-out
                                relative
                              `} style={{ background: "gray" }}>
                                                        <div className={`
                                  absolute w-6 h-6 rounded-full bg-white transition-transform duration-200 ease-in-out
                                  ${isAnniversaryMessageEnabled ? 'left-9' : 'left-1'}
                                `} style={{ top: '12%', left: '10%' }}></div>
                                                        <span className={`
                                  absolute text-xs font-medium top-2
                                  ${isAnniversaryMessageEnabled ? 'left-2 text-white' : 'right-2 text-gray-700'}
                                `} style={{ top: '25%', right: '10%' }}>
                                                            {isAnniversaryMessageEnabled ? 'ON' : 'OFF'}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>

                                            {isAnniversaryMessageEnabled && anniversaryTemplate && (
                                                <div className="flex flex-wrap gap-4 items-center justify-between bg-gray-50 p-4 rounded-lg">
                                                    <div>
                                                        <p className="font-medium">Selected Template:</p>
                                                        <p className="text-sm text-gray-600">{anniversaryTemplate.template_name}</p>
                                                    </div>
                                                    <button
                                                        onClick={handleShowAnniversaryModal}
                                                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium
                                        hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    >
                                                        Change Template
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    {showTemplateModal && (

                                        <div className="modal">
                                            <div className="modal-content modal-content-wide">
                                                <span
                                                    className="close -mt-5"
                                                    onClick={handleCloseTemplateModal}
                                                >
                                                    &times;
                                                </span>
                                                <div className="section-title ">
                                                    <h2 className="text-2xl font-semibold mt-3 mb-4 capitalize">
                                                        Select {autoMessageTemplateType} Message Template
                                                    </h2>
                                                    <hr />
                                                </div>
                                                {(!showVariablesInput && !selectedTemplate) ? (
                                                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 mt-6 gap-6">
                                                        {templatesData && templatesData.map((template) => (
                                                            template.status === "APPROVED" ? (
                                                                <div
                                                                    className={`relative flex flex-col justify-between rounded-md shadow dark:shadow-gray-700 overflow-hidden min-h-[300px]  ${autoMessageTemplateType === 'BIRTHDAY' ?
                                                                        birthdayTemplate && birthdayTemplate.id === template.id
                                                                            ? " border-4  !important"
                                                                            : "bg-white dark:bg-slate-900"
                                                                        : (autoMessageTemplateType === 'ANNIVERSARY' ?
                                                                            anniversaryTemplate && anniversaryTemplate.id === template.id
                                                                                ? " border-4  !important"
                                                                                : "bg-white dark:bg-slate-900"
                                                                            : null
                                                                        )
                                                                        }`}
                                                                >
                                                                    <div className="content p-6 flex-grow">
                                                                        <div className="flex align-center justify-between mb-4 ">
                                                                            <h6 className="text-15 font-bold">
                                                                                {template.template_name}
                                                                            </h6>
                                                                        </div>
                                                                        <SimpleBar style={{ maxHeight: "200px" }} autoHide={false}>
                                                                            <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.header_content}</pre>
                                                                            <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.content}</pre>
                                                                        </SimpleBar>
                                                                    </div>
                                                                    <div className="px-6 pb-6 relative bottom-0 left-0">
                                                                        <button
                                                                            onClick={() => {
                                                                                handleTemplateSelect(template);
                                                                            }}
                                                                            className={`py-2 px-5 mx-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md  ${autoMessageTemplateType === 'BIRTHDAY' ?
                                                                                birthdayTemplate && birthdayTemplate.id === template.id
                                                                                    ? "bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700"
                                                                                    : "bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                                                                                : (autoMessageTemplateType === 'ANNIVERSARY' ?
                                                                                    anniversaryTemplate && anniversaryTemplate.id === template.id
                                                                                        ? "bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700"
                                                                                        : "bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                                                                                    : null
                                                                                )
                                                                                }`}
                                                                        >
                                                                            Select Template
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="mt-6 rounded-md shadow dark:shadow-gray-700 p-4">
                                                        <div className="flex justify-between items-center mb-6">
                                                            <h4 className="text-1xl font-bold">Configure Template Variables</h4>
                                                            <button
                                                                onClick={() => {
                                                                    setShowVariablesInput(false);
                                                                    setSelectedTemplate(null);
                                                                }}
                                                                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                                            >
                                                                ← Back
                                                            </button>
                                                        </div>

                                                        <div className="mb-6" style={{ overflowX: 'auto' }}>
                                                            <h3 className="text-lg font-semibold mb-2">Template Preview</h3>
                                                            <div className="bg-gray-50 p-4 rounded-md">
                                                                <p className="font-medium mb-2">Template Name:</p>
                                                                <pre className="text-sm mb-4 whitespace-pre-wrap">{selectedTemplate.template_name}</pre>
                                                                <p className="font-medium mb-2">Header:</p>
                                                                <pre className="text-sm mb-4 whitespace-pre-wrap">{selectedTemplate.header_content}</pre>
                                                                <p className="font-medium mb-2">Content:</p>
                                                                <pre className="text-sm whitespace-pre-wrap">{selectedTemplate.content}</pre>
                                                            </div>
                                                        </div>

                                                        <form onSubmit={handleUpdateAutoMessagingTemplateData}>
                                                            {variableValues && Object.keys(variableValues).length > 0 && (
                                                                <div className="mb-6">
                                                                    <h3 className="text-lg font-semibold mb-4">Template Variables</h3>
                                                                    {Object.keys(variableValues).map((name) => (
                                                                        <div key={name} className="mb-4 pl-3">
                                                                            <label className="block text-start text-sm font-medium text-gray-700 mb-1 truncate capitalize">
                                                                                {name} <span className="text-gray-400">(example, {variableExamples[name] || ''})</span>
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="w-full p-2 border rounded-md"
                                                                                placeholder={variableExamples[name] || `Enter value for ${name}`}
                                                                                onChange={(e) => handleVariableInputChange(name, e.target.value)}
                                                                                value={variableValues[name]}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            <div className="flex justify-end">
                                                                <button
                                                                    type="submit"
                                                                    className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                                                                >
                                                                    Save Template Configuration
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Footer />
                        </main>
                    </div>
                )
            }

        </>
    );
}

export default AutoMessaging